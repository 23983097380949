<template>
	<div data-route>
		<page-header
			heading="Create new article"
		/>
		<div data-element="main">
			<article-edit
				:cancelRoute="getCancelRoute"
				:isCreate="true"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader   from '@/components/ui/PageHeader';
	import ArticleEdit  from '@/components/knowledgebase/edit/ArticleEdit';

	export default {
		metaInfo: {
			title: 'Create new article'
		},
		components: {
			PageHeader,
			ArticleEdit
		},
		data: () => ({}),
		computed: {
			getCancelRoute () {
				return '/admin/knowledgebase/articles';
			}
		},
		created () {},
		methods: {}
	};

</script>
