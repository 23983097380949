<template>
	<div data-component="article">
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="submit"
			v-if="getContentIsLoaded"
		>
			<input-field
				name="Title"
				type="text"
				rules="required"
				v-model="article.title"
			/>
			<input-field
				name="Slug"
				type="text"
				rules="required"
				v-model="article.slug"
			/>
			<wysiwyg-field
				name="Content"
				type="text"
				rules="required"
				v-model="article.content"
			/>
			<wysiwyg-field
				name="Excerpt"
				type="text"
				rules="required"
				v-model="article.excerpt"
			/>
			<checkbox-field
				name="Featured article"
				v-model="article.featured"
			/>
			<select-field
				name="Categories"
				:options="getCategories"
				v-model="article.category"
				multiple
			/>
			<actions
				:actions="getActions"
				@actionClick="onActionClick"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import InputField          from '@/components/forms/InputField';
	import CheckboxField       from '@/components/forms/CheckboxField';
	import SelectField         from '@/components/forms/SelectField';
	import Actions             from '@/components/ui/Actions';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import routeParams         from '@/mixins/routeParams';
	import api                 from '@/services/api';
	import { paramCase }       from 'change-case';

	export default {
		components: {
			InputField,
			WysiwygField: () => import('@/components/forms/WysiwygField'),
			CheckboxField,
			SelectField,
			Actions
		},
		mixins: [actionClick, invalidFormMessage, routeParams],
		props: {
			isCreate: {
				type: Boolean,
				default: false
			},
			cancelRoute: {
				type: String,
				default: ''
			},
			articleId: {
				type: String,
				default: ''
			}
		},
		data: () => ({
			article:     undefined,
			categories:  undefined
		}),
		computed: {
			getActions () {
				return {
					primary: [
						{
							text: this.isCreate ? 'Create' : 'Save',
							type: 'submit'
						},
						{
							text: 'Cancel',
							route: this.cancelRoute
						}
					],
					secondary: [
						(!this.isCreate ?
							{
								text: 'Delete',
								actionId: 'deleteArticle'
							} : false
						)
					].filter(Boolean)
				};
			},
			getCategories () {
				return this.categories.map((category) => {
					return {
						text: category.title,
						value: category.title
					};
				});
			},
			getContentIsLoaded () {
				return this.article && this.categories;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/knowledgebase',
						text: 'Knowledgebase'
					},
					{
						path: '/admin/knowledgebase/articles',
						text: 'Articles'
					},
					(this.isCreate ?
						{
							path: '/admin/knowledgebase/articles/create',
							text: 'New article'
						} : false
					),
					(!this.isCreate ?
						{
							path: `/admin/knowledgebase/categories/${this.getArticleId}`,
							text: this.article?.title
						} : false
					)
				].filter(Boolean);
			}
		},
		watch: {
			'article.title' (newVal, oldVal) {
				this.article.slug = paramCase(this.article.title);
			}
		},
		mounted () {
			this.setContent();
		},
		methods: {
			async setContent () {
				if (this.isCreate) {
					this.article = {
						id:        undefined,
						title:     '',
						slug:      '',
						content:   '',
						excerpt:   '',
						featured:  false
					};
				}
				try {
					await Promise.all(
						[
							this.setCategories(),
							(
								!this.isCreate ? this.setArticle() : false
							)
						].filter(Boolean)
					);
				} catch (error) {
					this.$store.commit('ui/showError');
					return;
				}
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async setCategories () {
				const categories = await api.admin.getAllKnowledgebaseCategories();
				if (!categories) {
					throw new Error();
				}
				this.categories = categories;
			},
			async setArticle () {
				const article = await api.admin.getKnowledgebaseArticleById({
					articleId: this.articleId
				});
				if (!article) {
					throw new Error();
				}
				this.article = {
					...this.article,
					...article
				};
			},
			async deleteArticle () {
				const response = await api.admin.deleteKnowledgebaseArticleById({
					articleId: this.articleId
				});
				if (!response) {
					return false;
				}
				await this.$router.push(this.cancelRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'Article has been deleted'
				});
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				if (this.isCreate) {
					const article = await api.admin.createKnowledgebaseArticle({
						article: this.article
					});
					if (!article?.id) {
						return;
					}
				} else {
					const article = await api.admin.updateKnowledgebaseArticleById({
						article:    this.article,
						articleId:  this.article.id
					});
					if (!article?.id) {
						return;
					}
				}
				await this.$router.push(this.cancelRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'The article has been ' + (this.isCreate ? 'created' : 'updated')
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='course'] {}

</style>
